class EnvironmentHelper {
  static getIsServer() {
    return typeof window === 'undefined';
  }

  static getIsClient() {
    return !this.getIsServer();
  }
}

export default EnvironmentHelper;
