
import StyleClassNamesBuilder from '@/ui-kit/styles/helpers/StyleClassNamesBuilder';

export default {
  name: 'HomeSection',
  props: {
    className: {
      default: '',
      type: String,
    },
    styles: {
      default: () => ({}),
      type: Object,
    },
    hasGradientBackground: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    sectionClassNames() {
      return new StyleClassNamesBuilder('home-section')
        .addConditionClassName('gradient-background', this.hasGradientBackground)
        .addClassName(this.className)
        .build();
    },
  },
};
