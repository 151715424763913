
import throttle from 'lodash.throttle';
import { StyleSizeType } from '@/ui-kit/styles/enums';
import StyleClassNamesBuilder from '@/ui-kit/styles/helpers/StyleClassNamesBuilder';
import { ClientEvent, Comparator } from '@/ui-kit/enums';
import {
  HOME_ALL_YOU_NEED_PHONE_ANIMATION_DELAY,
} from '../constants';
import WindowSize from '@/ui-kit/mixins/windowSize';
import LazyImage from '@/components/lazy-image';

const PHONE_HEIGHT = 570;

export default {
  name: 'HomePhone',
  components: {
    LazyImage,
  },
  mixins: [WindowSize],
  props: {
    isVisible: {
      default: true,
      type: Boolean,
    },
    sectionsParameters: {
      default: () => [],
      type: Array,
    },
  },
  data() {
    return {
      currentIndex: 0,
      phoneTop: -PHONE_HEIGHT,
    };
  },
  computed: {
    imagesParameters() {
      return this.sectionsParameters
        .filter((sectionParameters) => !!sectionParameters.image)
        .map((sectionParameters) => sectionParameters.image);
    },
    imageSizes() {
      return [
        {
          comparator: Comparator.Less,
          breakpoint: StyleSizeType.Medium,
          imageSize: 270,
        },
        {
          comparator: Comparator.Greater,
          breakpoint: StyleSizeType.Medium,
          imageSize: 270,
        },
      ];
    },
    isFirstImageActive() {
      return this.currentIndex === 0;
    },
    phoneStyle() {
      return { top: `${this.phoneTop}px` };
    },
    phoneClassNames() {
      return new StyleClassNamesBuilder('home-phone')
        .addConditionClassName('first', this.isFirstImageActive)
        .addConditionClassName('hidden', !this.isVisible)
        .build();
    },
  },
  mounted() {
    window.addEventListener(ClientEvent.Scroll, this.handleScroll);

    setTimeout(() => {
      this.calculatePhoneInitialTop();
    }, HOME_ALL_YOU_NEED_PHONE_ANIMATION_DELAY);
  },
  destroyed() {
    window.removeEventListener(ClientEvent.Scroll, this.handleScroll);
  },
  methods: {
    // eslint-disable-next-line func-names
    handleScroll: throttle(function () {
      this.calculatePhonePosition();
    }, 200),
    calculatePhonePosition() {
      const currentSectionIndex = this.sectionsParameters.findIndex(this.getIsCurrentSection);
      const currentSection = this.sectionsParameters[currentSectionIndex];

      if (!currentSection) {
        return;
      }

      this.currentIndex = currentSectionIndex;
      this.calculatePhoneTop(currentSection);
    },
    calculatePhoneTop(currentSection) {
      if (this.isGreaterThan(StyleSizeType.Medium)) {
        const centerOfCurrentSection = this.getCenterOfElement(
          currentSection.height,
          currentSection.top,
        );
        const phoneRef = this.$refs['home-phone'];
        // [BODE-4WD](https://bode-canada.sentry.io/issues/4401599249/)
        const halfOfPhone = (phoneRef?.offsetHeight || 0) / 2;

        this.phoneTop = centerOfCurrentSection - halfOfPhone;
      }
    },
    calculatePhoneInitialTop() {
      const [firstSection] = this.sectionsParameters;

      this.calculatePhoneTop(firstSection, this.currentIndex);
    },
    getIsCurrentSection(section) {
      const centerOfSection = this.getCenterOfElement(section.height, section.top);

      return window.scrollY < centerOfSection;
    },
    getCenterOfElement(height, top) {
      const halfOfSectionHeight = height / 2;

      return top + halfOfSectionHeight;
    },
    getIsPreviousImage(index) {
      return this.currentIndex > index;
    },
    getIsNextImage(index) {
      return this.currentIndex < index;
    },
    getIsCurrentImage(index) {
      return this.currentIndex === index;
    },
    getContentItemClassNames(index) {
      return new StyleClassNamesBuilder('home-phone-content-item')
        .addConditionClassName('previous', this.getIsPreviousImage(index))
        .addConditionClassName('active', this.getIsCurrentImage(index))
        .addConditionClassName('next', this.getIsNextImage(index))
        .build();
    },
  },
};
