
import Gutter from '@/ui-kit/components/gutter';
import { StylePositionType, StyleSizeType, StyleColorSchemeType } from '@/ui-kit/styles/enums';
import Button from '@/ui-kit/components/button';
import HomeSection from '../section';
import { HomePageEvent } from '@/constants/AnalyticEvents';

export default {
  name: 'BuyOrSellSection',
  components: { Button, Gutter, HomeSection },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    redirectTo: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      StyleColorSchemeType,
      StylePositionType,
      StyleSizeType,
      HomePageEvent,
    };
  },
};
