import EnvironmentHelper from './EnvironmentHelper';

class WindowSizeHelper {
  static getWidth() {
    return EnvironmentHelper.getIsClient() ? window.innerWidth : 0;
  }

  static getClientWidth() {
    return EnvironmentHelper.getIsClient() ? window.document.body.clientWidth : 0;
  }

  static getHeight() {
    return EnvironmentHelper.getIsClient() ? window.innerHeight : 0;
  }

  static getClientHeight() {
    return EnvironmentHelper.getIsClient() ? window.document.body.clientWidth : 0;
  }
}

export default WindowSizeHelper;
