
import Typography from '@/ui-kit/components/typography';
import { TypographyType, TypographyProperty } from '@/ui-kit/components/typography/enums';
import Button from '@/ui-kit/components/button';
import {
  ButtonProperty,
  ButtonPropsEventType,
  ButtonFillType,
} from '@/ui-kit/components/button/enums';
import Gutter from '@/ui-kit/components/gutter';
import { GutterProperty } from '@/ui-kit/components/gutter/enums';
import HomeSection from '../section';
import {
  StylePositionType,
  StyleSizeType,
  StyleColorSchemeType,
  StyleFontWeightType,
} from '@/ui-kit/styles/enums';
import routes from '@/constants/routes';
import StyleClassNamesBuilder from '@/ui-kit/styles/helpers/StyleClassNamesBuilder';
import WindowSize from '@/ui-kit/mixins/windowSize';
import {
  HOME_ALL_YOU_NEED_BACKGROUND_ANIMATION_DELAY,
  HOME_ALL_YOU_NEED_TITLE_ANIMATION_DELAY,
  HOME_ALL_YOU_NEED_BUTTON_ANIMATION_DELAY,
} from '../constants';
import HomeStoreName from '@/store/home/name';
import { HomePageEvent } from '@/constants/AnalyticEvents';

export default {
  name: 'HomeAllYouNeedSection',
  components: {
    Button,
    Typography,
    Gutter,
    HomeSection,
  },
  mixins: [WindowSize],
  data() {
    return {
      isBackgroundAnimationStarted: false,
      isTitleAnimationStarted: false,
      isButtonAnimationStarted: false,
      routes,
      StyleColorSchemeType,
      StylePositionType,
      StyleSizeType,
      StyleFontWeightType,
      HomePageEvent,
      ButtonProperty,
      ButtonPropsEventType,
      ButtonFillType,
      TypographyType,
      TypographyProperty,
      GutterProperty,
    };
  },
  computed: {
    content() {
      return this.$store.state[HomeStoreName].allYouNeedContent;
    },
    sectionStyle() {
      return {
        backgroundImage: `url('${this.content.image.url}')`,
      };
    },
    sectionClassName() {
      return new StyleClassNamesBuilder('all-you-need-section')
        .addConditionClassName('initialized', this.isBackgroundAnimationStarted)
        .build();
    },
    titleClassName() {
      return new StyleClassNamesBuilder('all-you-need-section__title')
        .addConditionClassName('initialized', this.isTitleAnimationStarted)
        .build();
    },
    buttonClassName() {
      return new StyleClassNamesBuilder('all-you-need-section-button')
        .addConditionClassName('initialized', this.isButtonAnimationStarted)
        .build();
    },
  },
  mounted() {
    setTimeout(() => {
      this.isBackgroundAnimationStarted = true;
    }, HOME_ALL_YOU_NEED_BACKGROUND_ANIMATION_DELAY);
    setTimeout(() => {
      this.isTitleAnimationStarted = true;
    }, HOME_ALL_YOU_NEED_TITLE_ANIMATION_DELAY);
    setTimeout(() => {
      this.isButtonAnimationStarted = true;
    }, HOME_ALL_YOU_NEED_BUTTON_ANIMATION_DELAY);
  },
};
