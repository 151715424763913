import throttle from 'lodash.throttle';
import WindowSizeHelper from '../../helpers/WindowSizeHelper';
import { Breakpoints } from '../../styles/constants';
import { StyleSizeType } from '../../styles/enums';
import { ClientEvent } from '../../enums';

// @vue/component
const WindowSize = {
  data() {
    return {
      currentBreakpoint: null,
      breakpoints: StyleSizeType,
      windowWidth: WindowSizeHelper.getWidth(),
      windowHeight: WindowSizeHelper.getHeight(),
    };
  },
  mounted() {
    this.setWindowDimensions();
    this.setCurrentBreakpoint();
    window.addEventListener(ClientEvent.Resize, this.handleResize, { passive: true });
  },
  destroyed() {
    window.removeEventListener(ClientEvent.Resize, this.handleResize);
  },
  methods: {
    isSmallerThan(breakpoint) {
      return this.windowWidth <= Breakpoints[breakpoint];
    },
    isGreaterThan(breakpoint) {
      return this.windowWidth >= Breakpoints[breakpoint];
    },
    isBetween(minWidthBreakpoint, maxWidthBreakpoint) {
      return this.isGreaterThan(minWidthBreakpoint) && this.isSmallerThan(maxWidthBreakpoint);
    },
    // eslint-disable-next-line prefer-arrow-callback, func-names
    handleResize: throttle(function () {
      this.setWindowDimensions();
      this.setCurrentBreakpoint();
    }, 300),
    setWindowDimensions() {
      this.windowWidth = WindowSizeHelper.getWidth();
      this.windowHeight = WindowSizeHelper.getHeight();
    },
    setCurrentBreakpoint() {
      const newCurrentBreakpoint = this.getCurrentBreakpoint();

      if (newCurrentBreakpoint === this.currentBreakpoint) {
        return;
      }

      this.currentBreakpoint = newCurrentBreakpoint;
    },
    getCurrentBreakpoint() {
      return Object.entries(Breakpoints)
        .reduce((previousSize, [size, breakpoint]) => {
          const previousBreakpoint = Breakpoints[previousSize];

          if (breakpoint > previousBreakpoint && breakpoint < this.windowWidth) {
            return size;
          }

          return previousSize;
        }, StyleSizeType.XSmall);
    },
  },
};

export default WindowSize;
