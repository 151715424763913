
import BuyOrSellSection from '../buy-or-sell-section';
import HomeAllYouNeedSection from '../all-you-need-section';
import routes from '@/constants/routes';
import HomePhone from '@/components/page-specific/home/phone';
import HomeStoreName from '@/store/home/name';
import WindowSize from '@/ui-kit/mixins/windowSize';
import { StyleSizeType } from '@/ui-kit/styles/enums';

export default {
  name: 'HomeBlockWithPhone',
  components: {
    HomePhone,
    BuyOrSellSection,
    HomeAllYouNeedSection,
  },
  mixins: [WindowSize],
  props: {},
  data: () => ({
    routes,
    buySectionParameters: {},
    sellSectionParameters: {},
  }),
  computed: {
    isPhoneVisible() {
      return this.isGreaterThan(StyleSizeType.Medium);
    },
    phoneContent() {
      return this.$store.state[HomeStoreName].phoneContent;
    },
    buyContent() {
      return this.$store.state[HomeStoreName].buyYourHomeContent;
    },
    sellContent() {
      return this.$store.state[HomeStoreName].sellYourHomeContent;
    },
    sectionsParameters() {
      return [
        this.sellSectionParameters,
        this.buySectionParameters,
      ];
    },
  },
  mounted() {
    this.setSectionsParameters();
  },
  methods: {
    setSectionsParameters() {
      const sellSectionRef = this.$refs['home-sell-section'];
      const buySectionRef = this.$refs['home-buy-section'];

      this.buySectionParameters = this.getSectionParameters(
        buySectionRef,
        this.phoneContent.third_image,
      );
      this.sellSectionParameters = this.getSectionParameters(
        sellSectionRef,
        this.phoneContent.second_image,
      );
    },
    getSectionParameters(ref, image) {
      return {
        image,
        height: ref.offsetHeight,
        top: ref.offsetTop,
      };
    },
  },
};
