export const HOME_ALL_YOU_NEED_BACKGROUND_ANIMATION_DELAY = 0;
export const HOME_ALL_YOU_NEED_TITLE_ANIMATION_DELAY = 800;
export const HOME_ALL_YOU_NEED_BUTTON_ANIMATION_DELAY = 1200;
export const HOME_ALL_YOU_NEED_PHONE_ANIMATION_DELAY = 1200;

export default {
  HOME_ALL_YOU_NEED_BACKGROUND_ANIMATION_DELAY,
  HOME_ALL_YOU_NEED_TITLE_ANIMATION_DELAY,
  HOME_ALL_YOU_NEED_BUTTON_ANIMATION_DELAY,
  HOME_ALL_YOU_NEED_PHONE_ANIMATION_DELAY,
};
